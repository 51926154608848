@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'
































































































































































.icons-top-row
  background-color $accent-4
  border-top-left-radius 25px
  border-top-right-radius 25px
  border-style ridge
  border-bottom-width 0px
  border-top-width 1px

.icons-middle-row
  background-image url("/icons/marker-default.svg")
  background-repeat no-repeat
  background-position center center
  background-color $accent-4
  border-style ridge
  border-top-width 0px
  border-bottom-width 0px
  border-left-width 2px
  border-right-width 2px

.icons-middle-row-center
  border-style solid
  border-width 1px

.icons-bottom-row
  background-color $accent-4
  border-bottom-left-radius 25px
  border-bottom-right-radius 25px
  border-style ridge
  border-top-width 0px
  border-bottom-width 1px

