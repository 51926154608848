@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'




















































































































































































































































































































































































.leaflet-container
  font-size 1em
  font-family "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace!important
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  font-weight 500
  letter-spacing 0.0325em

.geohash-bounding-box-text
  background-color $neutral-1

.leaflet-overlay-pane /* fixes 1.6.0 */
  z-index 401

.leaflet-left .leaflet-control
    margin-left 10px
    margin-top 40px

.geodocr-map-tooltip
  width 120px
  overflow-wrap break-word
  word-wrap break-word
  word-break break-all
  white-space -moz-pre-wrap !important  /* Mozilla, since 1999 */
  white-space -pre-wrap      /* Opera 4-6 */
  white-space -o-pre-wrap    /* Opera 7 */
  white-space pre-wrap       /* css-3 */
  word-wrap break-word       /* Internet Explorer 5.5+ */
  white-space -webkit-pre-wrap /* Newer versions of Chrome/Safari*/
  word-break break-all
  white-space normal

.flash-message-border
  outline 2px dashed $primary-1
  box-shadow 0 0 0 2px $warning
  animation 1s animateBorder infinite
@keyframes animateBorder
  to
    outline-color $warning
    box-shadow 0 0 0 3px $primary-5

