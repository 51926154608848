@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'



































































































































.leaflet-overlay-pane
  z-index 800
.hash-marker
  font-family Courier New
  color white
  text-shadow -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000
  font-weight bold
  letter-spacing $hash-letter-spacing
  text-transform uppercase
  z-index 900
