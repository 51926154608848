@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'































body
  font-family "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter",monospace!important
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  font-weight 500
  letter-spacing 0.0325em
.text-neutral
  color #E0E1E2

.bg-primary-1
  background $primary-1
.bg-primary-2
  background $primary-2
.bg-primary-3
  background $primary-3
.bg-primary-4
  background $primary-4
.bg-primary-5
  background $primary-5

html
  overflow-y scroll
  scrollbar-color $secondary-2 $primary-2
html::-webkit-scrollbar
  display none
  -ms-overflow-style: none;
  overflow -moz-scrollbars-none

a:link
  color $info
a:visited
  color $info-2
a:hover
  color $positive
a:active
  color $positive
