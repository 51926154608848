@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'

















.popup-display
  background-color $primary-2

