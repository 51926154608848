@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'

































































































































































































.child-rect {
  fill: $negative;
  stroke: red;
  stroke-width: 4px;
  opacity: 0.3;
}

.component-rect {
  fill: $positive;
  border-style: solid;
  opacity: 0.3;
}

.page-rect {
  fill: $neutral;
  opacity: 0.3;
}

.container-rect {
  z-index: $top-map !important;
}

.view-data {
  z-index: $top-fullscreen;
}

.highlight-rect {
  border-style: solid;
  border-width: 1px;
}
