@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'

























































































































































































































































































































































@keyframes example
  from
    fill $info
  to
    fill $accent

.pulse-ready
  animation-name example
  animation-duration 3s
  animation-fill-mode forwards
