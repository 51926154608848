@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'















































































input.input-page::-webkit-outer-spin-button,
input.input-page::-webkit-inner-spin-button
  -webkit-appearance none
  margin 0

input.input-page[type=number]
  -moz-appearance textfield

.input-page
  margin: 0
  background-color $accent
  border-radius 30% 30% 30% 30%
  border 2px solid $secondary
  max-width 2em
  max-height 2em
  font-size 0.8em
  font-weight bold
  text-align center
