















































































































































div.feature-selected > svg > g > path
  stroke yellow!important
path.feature-selected
  stroke yellow!important

