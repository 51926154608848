@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'














































































.tertiary {
  fill: $tertiary;
}

.info {
  fill: $info;
}

.positive {
  fill: $positive;
}

.secondary {
  fill: $secondary;
}

.primary {
  fill: $primary;
}

@keyframes example {
  from {
    fill: $info;
  }

  to {
    fill: $dark;
  }
}

.pulse-ready {
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
