
























































































































































































































































































































































.q-item__section--avatar
  min-width 24px
  padding-right 6px
