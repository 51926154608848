#Heading,
.text-alignment {
  display: none;
}
.move-nodes-hover + .move-nodes-hover-children {
  display: none;
}
.move-nodes-hover:hover + .move-nodes-hover-children {
  display: block;
}
.move-nodes-group:hover > .move-nodes-hover-children {
  display: block;
}
#ImageOffline,
.figure-caption-background {
  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6PxMAAqABmy7EG5gAAAAASUVORK5CYII=") !important;
  background-repeat: repeat !important;
  background-color: rgba(255,255,255,0.6) !important;
}
div.printable:hover > p {
  opacity: 0.8;
}
#metadata variables,
.ProseMirror > figure {
  display: inline-grid;
  margin: 1em;
}
ul[data-visible="false"] {
  display: none;
}
ul[data-visible="true"] {
  display: block;
}
ul[data-type="var_list"] {
  padding: 3px;
  border: #000;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
}
.ProseMirror > p::before {
  content: 0 0B 6;
}
var {
  display: inline-flex;
}
div[data-d="1"] > section {
  padding: 1em;
  border-left: solid;
  border-left-color: $primary;
}
section[data-d="1"] > div > p {
  border-bottom: solid;
  border-left-color: $primary;
  border-bottom-width: 1px;
}
header[data-d="2"] > div > p {
  border-bottom: solid;
  border-left-color: $primary;
  border-bottom-width: 1px;
  border-left: solid;
  border-left-color: $primary;
  border-left-width: 1px;
  padding-left: 1em;
  margin-bottom: 0px;
}
header[data-d="2"] > div > ul {
  margin-top: 0px;
  display: inline-flex;
  background: rgba(17,17,17,0.067);
  border-radius: 18px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-left: solid;
  border-left-color: $primary;
  border-left-width: 1px;
}
div[data-d="3"] > p {
  margin: 0;
}
div[data-type="bullet_item"] {
  border-left-color: $primary;
  border-bottom-width: 1px;
  border-left: solid;
  border-bottom: solid;
  border-left-color: $primary;
  border-bottom-width: 1px;
}
ul {
  margin-left: 0.1em !important;
}
var p {
  margin: 0 !important;
  padding: 0 !important;
}
ul[data-type="var_list"] {
  padding-left: 1em;
}
.ProseMirror {
  position: relative;
}
.ProseMirror {
  word-wrap: break-word;
  white-space: pre-wrap;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
}
.ProseMirror pre {
  white-space: pre-wrap;
}
.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}
.ProseMirror-gapcursor:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 1px solid #000;
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}
.ProseMirror-hideselection *::selection {
  background: transparent;
}
.ProseMirror-hideselection *::-moz-selection {
  background: transparent;
}
.ProseMirror-hideselection * {
  caret-color: transparent;
}
.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}
@-moz-keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}
@-o-keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}
@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}
body {
  font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  letter-spacing: 0.0325em;
}
.text-neutral {
  color: #e0e1e2;
}
.bg-primary-1 {
  background: rgba(38,40,55,0.996);
}
.bg-primary-2 {
  background: rgba(38,40,55,0.667);
}
.bg-primary-3 {
  background: rgba(38,40,55,0.467);
}
.bg-primary-4 {
  background: rgba(38,40,55,0.267);
}
.bg-primary-5 {
  background: rgba(38,40,55,0.133);
}
html {
  overflow-y: scroll;
  scrollbar-color: rgba(76,86,115,0.667) rgba(38,40,55,0.667);
}
html::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
a:link {
  color: #aad3dd;
}
a:visited {
  color: rgba(170,211,221,0.667);
}
a:hover {
  color: #50aa4c;
}
a:active {
  color: #50aa4c;
}
/*# sourceMappingURL=src/App.css.map */